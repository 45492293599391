<template>
  <div class="Attestation_box">
    <div class="content">
      <div class="tips" style="
          font-size: 16px;
          text-align: center;
          margin-bottom: 20px;
          color: red;
        ">
        依据彩票管理条例规定购彩兑奖需实名认证
      </div>
      <van-form @failed="onFailed" @submit="onSubmit" colon>
        <van-cell-group>
          <van-field v-model="formData.realName" name="realName" label="姓名" placeholder="姓名"
            :rules="[{ required: true, message: '请填写真实姓名' }]" />
        </van-cell-group>
        <van-cell-group>
          <!-- 通过 pattern 进行正则校验 -->
          <van-field v-model="formData.mobile" name="mobile" label="手机号" placeholder="手机号" maxlength="11" :rules="[
            { pattern: pageData.pattern, message: '请输入正确手机号' },
          ]" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="formData.idCard" name="idCard" label="身份证" placeholder="身份证" maxlength="18"
            :rules="[{ required: true, message: '请填写正确身份证号' }]" />
        </van-cell-group>

        <input v-if="imgUrl" class="img-url" type="image" name="imgUrl" :src="imgUrl">
        <div style="margin-top: 8vh; padding: 0 20px">
          <van-button round block type="danger" @click="face" :text="!imgUrl?'人脸识别':'重新识别'">
          </van-button>
        </div>
        <div style="margin-top: 8vh; padding: 0 20px">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  ref,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
} from "vue";
import { Form, Field, CellGroup, Button, Toast, Dialog, Tab } from "vant";
import { request, requestHeader } from "../../utils/request";
import { useRouter, useRoute } from "vue-router";
import { verifyIdCard, getAge } from "../../utils/util";

export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let placeAlias = route.params.placeAlias;
    let imgUrl = route.params.imgUrl;
    let pageData = reactive({
      pattern: /^1\d{10}$/, //手机号正则
    });
    // 表单数据
    let formData = reactive({
      realName: route.params.realName, //姓名
      mobile: route.params.mobile, //手机号
      idCard: route.params.idCard, //身份证
    });
    // 表单效验不通过
    const onFailed = (errorInfo) => {
      console.log("fail", errorInfo);
    };
    //表单提交
    const onSubmit = (values) => {
      let { realName, mobile, idCard } = values;
      let formData = new FormData();
      if(!imgUrl) {
        Toast("请先完成人脸识别")
        return
      }
      if(!verifyIdCard(idCard)) {
        Toast("身份证格式有误")
        return
      } else {
        if(getAge(idCard) < 18) {
          Toast("未成年人禁止购彩")
          return
        }
      }
      formData.append("realName", realName);
      formData.append("mobile", mobile);
      formData.append("idCard", idCard);
      formData.append("imgUrl", imgUrl);
      request({
        url: "/h5/lottery/realNameCertification",
        method: "post",
        data: formData,
      }).then((res) => {
        if (res.success) {
          Dialog.alert({
            message: "实名认证成功, 请继续扫码购票",
          }).then(() => { 
            // 退出网页
            setTimeout(function() {
              //这个可以关闭安卓系统的手机
              document.addEventListener(
                "WeixinJSBridgeReady",
                function() {
                  WeixinJSBridge.call("closeWindow");
                },
                false
              );
              //这个可以关闭ios系统的手机
              WeixinJSBridge.call("closeWindow");
            }, 300);
          });
        } else {
          Dialog.alert({
            message: res.error,
          }).then(() => { });
        }
      });
    };
    const face = () => {
      let {realName, mobile, idCard} = formData;
      router.push({
        name:'face',
        params:{realName, mobile, idCard}
      })
    };
    return {
      placeAlias,
      imgUrl,
      pageData,
      formData,
      onFailed,
      onSubmit,
      face,
    };
  },
};
</script>

<style lang="less" scoped>
.Attestation_box {
  background: white;
  height: 100vh;

  .content {
    padding: 20px;

    .img-url {
      display: block;
      width: 200px;
      height: 200px;
      margin: 20px auto;
    }
  }
}
</style>
