<template>
  <div class="User">
    <div class="content">
      <div class="amount">
        <div class="box">
          <div class="txt">可提现金额(元)</div>

          <div class="money">{{ user_data.lotteryCore }}</div>
        </div>
      </div>

      <div class="totalRecord">
        <div class="box">
          <div class="total">
            已提现金额（元）

            <div class="num">{{ user_data.totalWithdrawL }}</div>
          </div>

          <span>|</span>

          <div class="total">
            今日提现（元）

            <div class="num">{{ user_data.todayWithdraw }}</div>
          </div>
        </div>
      </div>

      <div class="op_area">
        <van-button
          round
          type="primary"
          @click="toCash"
          >奖金提现</van-button
        >

        <van-button round type="primary" :to="'/detailed/' + placeAlias">钱包明细</van-button>
      </div>

      <div class="detailed">
        <div class="text">提现明细</div>

        <div class="list_box">
          <div class="txt">
            <div>日期</div>
            <div>提现金额</div>
            <div>状态</div>
          </div>

          <div class="listBox" v-if="WithdrawList.length > 0">
            <van-list
              class="list_item"
              v-model:loading="user_data.loading"
              :finished="user_data.more"
              finished-text="没有更多了"
              @load="scroll"
            >
              <div
                class="list_content"
                v-for="item in WithdrawList"
                :key="item.pid"
              >
                <div class="items">
                  <div class="times">
                    <div style="margin-bottom: 5px">
                      {{ formatTime(item.createTime).split(" ")[0] }}
                    </div>
                    <div>{{ formatTime(item.createTime).split(" ")[1] }}</div>
                  </div>

                  <div>{{ item.money }}</div>

                  <div
                    class="states"
                    :style="{
                      color: item.status == 1 ? '#FF0000' : '#45E64F',
                    }"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </van-list>
          </div>

          <div class="listBox noData" v-else>
            <van-empty description="暂无数据" image-size="50vw" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Empty, Toast, List, Dialog } from "vant";
import { reactive, ref, onBeforeMount, onMounted } from "vue";
import { request, requestBtn } from "../../utils/request";
import { formatTime } from "../../utils/times";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let placeAlias = route.params.placeAlias;

    let user_data = reactive({
      todayWithdraw: 0, //今日提现
      lotteryCore: 0, //用户可提现总额
      totalWithdrawL: 0, //总计已提现金额
      pageNo: 1, //页数
      pageSize: 10, //列表个数
      loading: false, //加载图标
      more: false, //是否全部加载
    });

    let scrollRef = ref(null); // 内容盒子

    // 提现明细列表
    let WithdrawList = reactive([]);
    // 提现明细
    const Withdraw = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 1000,
      });
      let { pageNo, pageSize } = user_data;
      return request({
        url: "/h5/lottery/pagingLotteryUserWithdraw",
        params: { pageNo, pageSize, placeAlias },
      }).then((res) => {
        if (res.success) {
          let result = res.result;
          if (result.total > WithdrawList.concat(result.data)) {
            user_data.more = false;
          } else {
            user_data.more = true;
          }
          isSource(result.data);
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 列表数据处理
    const isSource = (list) => {
      for (let i = 0; i < list.length; i++) {
        let type = list[i].status;
        let name;
        switch (type) {
          case 0:
            name = "提现失败";
            break;
          case 1:
            name = "提现成功";
            break;
          case 2:
            name = "正在审核";
            break;
        }
        list[i].name = name;
      }
      Object.assign(WithdrawList, WithdrawList.concat(list));
    };
    // 滚动条到底加载更多
    const scroll = () => {
      user_data.loading = true;
      user_data.pageNo += 1;
      Withdraw();
    };
    // 获取页面数据
    const loadMore = () => {
      getUserInfo();
      Withdraw();
      getTodayWithdraw();
      getTotalWithdraw();
    };
    // 获取用户信息
    const getUserInfo = () => {
      request({
        url: "/h5/lottery/getUserInfo",
        params: { "placeAlias": placeAlias },
      }).then((res) => {
        if (res.success) {
          user_data.lotteryCore = res.result.lotteryCore;
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 提现跳转
    const toCash = () => {
      router.push({ name: "cash", params: { placeAlias: placeAlias } });
    };
    // 获取今日结算
    const getTodayWithdraw = () => {
      request({
        url: "/h5/lottery/getTodayWithdraw",
        params: { "placeAlias": placeAlias },
      }).then((res) => {
        if (res.success) {
          user_data.todayWithdraw = res.result;
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 总计已提现金额
    const getTotalWithdraw = () => {
      request({
        url: "/h5/lottery/getTotalWithdraw",
        params: { "placeAlias": placeAlias },
      }).then((res) => {
        if (res.success) {
          user_data.totalWithdrawL = res.result;
        } else {
          Toast.fail(res.error);
        }
      });
    };

    onMounted(() => {
      loadMore();
    });

    return {
      user_data,
      WithdrawList,
      placeAlias,
      scroll,
      formatTime,
      toCash
    };
  },
};
</script>

<style lang="less" scoped>
.User {
  .content {
    .amount {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;

      .box {
        border: 1px solid #fe6539;
        border-radius: 50%;
        width: 130px;
        height: 130px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .txt {
          font-size: 14px;
          color: black;
          font-weight: 600;
        }

        .money {
          font-size: 18px;
          color: #c50a11;
          font-weight: bold;
          padding-top: 10px;
        }
      }
    }

    .totalRecord {
      padding: 0 10px;

      .box {
        height: 60px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #bdb5b5;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .total {
          display: flex;
          flex-flow: column;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          color: black;
          font-weight: 600;
          height: 100%;

          .num {
            font-size: 15px;
            color: #d4151b;
            font-weight: 600;
          }
        }

        span {
          font-size: 14px;
          font-weight: bold;
          padding-top: 8px;
        }
      }
    }

    .op_area {
      padding: 20px 0 10px;
      display: flex;
      justify-content: space-around;

      .van-button {
        background: #fe6539;
        border: none;
        border-radius: 5px;
        width: 30vw;
        height: 40px;
        font-size: 16px;
      }
    }

    .detailed {
      .text {
        font-weight: bolder;
        font-size: 14px;
        color: black;
        padding-left: 10px;
      }

      .list_box {
        .txt {
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          border-bottom: 2px solid #eaeaea;
          padding: 6px 20px;
        }

        .list_item {
          overflow-y: scroll;
          height: calc(100vh - 340px);

          .list_content {
            display: flex;
            flex-flow: column;
            align-items: center;

            .items {
              width: 100%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              font-size: 14px;
              height: 60px;
              border-bottom: 2px solid #eaeaea;

              .times {
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
              }

              .states {
                color: #ce464a;
              }
            }
          }
        }
      }
    }
  }
}
</style>
