<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>

<script>
import { reactive, onBeforeMount, onMounted, getCurrentInstance } from "vue";
import wxmeans from "./utils/wxmeans";
import VConsole from "vconsole";

export default {
  setup() {

    onBeforeMount(() => {
      // if (
      //   process.env.VUE_APP_MODE == "development" &&
      //   location.host != "localhost:8091"
      // ) {
      //   const vConsole = new VConsole();
      // }
      wxmeans();
    });

    return {};
  },
};
</script>

<style>
#app {
  height: 100vh;
}
</style>
