<template>
  <div class="Cash">
    <div class="content">
      <div class="top">
        <div class="bank">提现到微信</div>
        <div class="bankCard">到账时间: 24小时以内</div>
      </div>

      <div class="center">
        <div class="txt">提现金额</div>

        <div class="money">
          <van-cell-group :border="false">
            <van-field
              id="moneyInp"
              v-model="cashData.moneyInp"
              type="number"
              label="¥"
              @input="propose"
            />
          </van-cell-group>
        </div>

        <div
          class="tips"
          :style="{ color: cashData.colorBan ? '#FF0000' : '#999999' }"
        >
          当前可提现余额{{ userInfo.lotteryCore }}元
        </div>

        <van-button
          type="primary"
          size="large"
          @click="submit"
          :disabled="cashData.submitBtn"
          >确认提现</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { react } from "@babel/types";
import { Button, Toast, Field, CellGroup, Dialog } from "vant";
import { request, requestBtn } from "../../utils/request";
import {
  reactive,
  ref,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const router = useRouter();
    
    let cashData = reactive({
      moneyInp: "", //提现输入框
      submitBtn: true, //提现按钮状态
      colorBan: false, //提示文字
    });
    const route = useRoute();
    let placeAlias = route.params.placeAlias;
    // 用户信息
    let userInfo = reactive({});
    // 输入框变化时
    const propose = () => {
      if (!cashData.moneyInp || cashData.moneyInp < 1) {
        cashData.colorBan = false;
        cashData.submitBtn = true;
      } else if (userInfo.lotteryCore * 1 >= cashData.moneyInp * 1) {
        cashData.colorBan = false;
        cashData.submitBtn = false;
      } else {
        cashData.submitBtn = true;
        cashData.colorBan = true;
      }
    };

    // 提现请求
    const submit = () => {
      Dialog.confirm({
        title: '提示',
        messageAlign: 'left',
        confirmButtonText: '继续提现',
        cancelButtonText: '返回兑奖',
        message:
          "因微信限制，每个用户每天只能提现10次，每次最高500元，请确保手上已中奖彩票全部兑奖完毕在进行提现操作！",
      }).then((res) => {
        if (cashData.moneyInp * 1 > 500) {
          Dialog.alert({
            message: '由于微信限制，单次提现金额不能超过500元，敬请谅解。'
          })
        } else {
          Toast.loading({
            duration: 0,
            message: "加载中...",
            forbidClick: true,
          });
          let formData = new FormData();
          formData.append("money", cashData.moneyInp);
          formData.append("placeAlias", placeAlias);
          console.log(placeAlias);
          request({
            method: "post",
            url: "/h5/lottery/withdraw",
            data: formData,
            // params: { money: cashData.moneyInp },
          }).then((res) => {
            Toast.clear();
            if (res.success) {
              Dialog.alert({
                message: res.result,
              }).then(() => {
                // on close
                router.replace({name:'user', params: {placeAlias}})
              });
            } else {
              Dialog.alert({
                message: res.error,
              }).then(() => {
                // on close
                router.replace({name:'user', params: {placeAlias}})
              });
            }
          });
        }
      });
    };
    // 获取用户信息
    const getUserInfo = () => {
      request({
        url: "/h5/lottery/getUserInfo",
        params: { "placeAlias": placeAlias },
      }).then((res) => {
        if (res.success) {
          Object.assign(userInfo, res.result);
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 挂在后
    onMounted(() => {
      getUserInfo();
    });

    return {
      cashData,
      userInfo,
      propose,
      submit,
    };
  },
};
</script>

<style lang="less">
.Cash {
  background: #dddddd;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;

  .content {
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;

    .top {
      background: #eeeeee;
      padding: 5px;

      .bank {
        font-size: 16px;
        color: black;
        display: flex;
      }

      .bankCard {
        color: #999999;
        font-size: 14px;
      }
    }

    .center {
      padding: 20px;

      .txt {
        font-size: 16px;
      }

      .money {
        border-bottom: 1px solid #aaaaaa;

        .van-cell-group {
          padding: 10px 0;

          .van-field__label {
            font-size: 24px;
            color: black;
            width: 20px;
            display: flex;
            align-items: center;
          }

          #moneyInp {
            font-size: 24px;
            color: black;
          }
        }
      }

      .tips {
        font-size: 14px;
        padding: 20px 0;
      }

      .van-button {
        border: none;
        border-radius: 10px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}
</style>
