import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import VueCanvasPoster from 'vue-canvas-poster'
import 'amfe-flexible'
// 引入全局样式表
import './assets/css/global.css'
import vueWechatTitle from 'vue-wechat-title'
import placeAlias from './utils/placeAlias'


const app = createApp(App);
app.use(router);
app.use(VueCanvasPoster);
app.use(vueWechatTitle);

app.config.globalProperties.$placeAlias = placeAlias  //渠道别名 

app.mount('#app');
