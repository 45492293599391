<template>
  <div class="Error">
    <div class="error_box">
      <img src="../../assets/error_img.png" alt="">
      <div class="text">
        兑奖未成功请勿离开设备，请立即联系工作人员
      </div>
    </div>
    
  </div>
</template>

<script>
import { Button } from "vant";
export default {
  components: {
    [Button.name]: Button
  },
}
</script>

<style lang="less" scoped>
.Error{
  background: #F8F8F8;
  height: 100%;

  .error_box{
    height: 80%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    
    img{
      width: 100%;
    }

    .text{
      width: 80%;
      font-size: 20px;
      margin: 10px auto;
    }
  }
}

</style>