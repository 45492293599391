// 封装axios请求的模块
import axios from 'axios'
import { getLocalToken, removeLocalToken } from './token'
import { Toast } from "vant";

const BASE_URL = '/api';
console.log(process.env);

// 用axios重新生成了请求的实例
const server = axios.create({
    baseURL: BASE_URL, // 项目发送axios请求的公共地址
    timeout: 20000, // 请求超时时间 这里是请求超过二十秒后还没有获得请求结果 提示请求超时
    withCredentials: true,
})

// 请求拦截
server.interceptors.request.use(config => {

    return config // 将配置完成的token返回 如果不返回 请求不会继续进行
}, err => {
    // 请求发生错误时的回调函数
    // 这里的意思是请求发送错误时将错误抛出

    return Promise.reject(err) // 使用promise将错误信息返回出去
})

// 响应拦截
server.interceptors.response.use(res => {
    
    return res.data
}, err => {
    console.log(err);

    if (err.response.status === 401) {
        // 清除token
        removeLocalToken()
        window.location.reload()
    } else {
        return Promise.reject(err)
    }
})

// 头部带token的请求
// 用axios重新生成了请求的实例

const serverBtn = axios.create({
    baseURL: BASE_URL, // 项目发送axios请求的公共地址
    timeout: 20000, // 请求超时时间 这里是请求超过二十秒后还没有获得请求结果 提示请求超时
    withCredentials: true,
})

// 请求拦截
serverBtn.interceptors.request.use(config => {

    return config // 将配置完成的token返回 如果不返回 请求不会继续进行
}, err => {
    // 请求发生错误时的回调函数
    // 这里的意思是请求发送错误时将错误抛出

    return Promise.reject(err) // 使用promise将错误信息返回出去
})

// 响应拦截
serverBtn.interceptors.response.use(res => {
    
    return res.data
}, err => {
    console.log(err);

    if (err.response.status === 401) {
        // 清除token
        removeLocalToken()
        window.location.reload()
    } else {
        return Promise.reject(err)
    }
})


export { server, serverBtn }