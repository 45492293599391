<template>
  <div class="Details">
    <div class="content">

      <!-- 揭示信息区域 -->
      <div class="reveal">
        <div class="prize">恭喜中奖！</div>

        <div class="reveal_img">
          <img src="../../assets/details/prize_5.png" alt="" />
        </div>

        <div class="txt">奖金正在转入您的微信账户</div>
      </div>

      <!-- 彩票信息 -->
      <div class="lottery_info">
        <div class="info_box">

          <div class="logo">
            <img src="../../assets/details/scan_logo.png" alt="" />

            <div class="name">体育彩票</div>
          </div>

          <div class="info">
            <div class="text">超级加倍</div>

            <div class="ticket">
              票号：360777000590859056
            </div>

            <div class="time">
              兑奖时间：2022-04-28 13:15:17
            </div>
          </div>

        </div>
      </div>
      
      <!-- 操作区 -->
      <div class="operate">

        <div class="button_area">

          <van-button round type="primary" to="record">兑奖记录</van-button>

          <van-button round type="primary" to="/">继续兑奖</van-button>

        </div>

        <div class="help">
          如有疑问，请查看
          <a href="">兑奖帮助</a>
        </div>

      </div>

      <!-- 广告区 -->
      <div class="ad_area">
        <img src="../../assets/details/advert.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { reactive, ref, onBeforeMount, onMounted } from "vue";
import { request, requestBtn } from "../../utils/request";
export default {
  components: {
    [Button.name]: Button
  },
};
</script>

<style lang="less" scoped>
.Details{

  .content{

    .reveal{
      padding-top: 10px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .prize{
        font-size: 18px;
        font-weight: bold;
        color: #FB171D;
      }

      .reveal_img{
        padding: 0 40px;
        max-height: 440px;
        overflow: hidden;

        img{
          width: 100%;
        }
      }

      .txt{
        font-size: 14px;
        color: #8D8D8D;
      }
    }

    .lottery_info{
      padding: 20px 10px 15px;

      .info_box{
        border: 2px solid #BA9093;
        border-radius: 6px;
        padding: 10px;

        .logo{
          display: flex;
          align-items: center;

          img{
            width: 24px;
            height: 24px;
          }

          .name{
            margin-left: 16px;
            font-size: 14px;
            font-weight: 600;
            color: #323232;
          }

        }

        .info{
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          align-items: center;
          height: 65px;

          .text{
            font-size: 14px;
            color: #323232;
            font-weight: 600;
            margin-bottom: 10px;
          }

          .ticket{
            font-size: 12px;
            color: #323232;
          }

          .time{
            font-size: 12px;
            color: #323232;
          }
        }

      }
    }

    .operate{
      padding: 0 20px 15px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .button_area{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .van-button{
          background: #FF4E60;
          height: 30px !important;
          border: none;
          color: white;
          font-size: 14px;
          padding: 0 30px;
        }

      }

      .help{
        margin-top: 10px;
        font-size: 13px;
        color: #323232;
        font-weight: 600;
      }

    }

    .ad_area{
      padding: 0 20px;

      img{
        width: 100%;
      }
    }
    
  }
}
</style>