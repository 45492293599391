import Cookies from 'js-cookie';
 
const TokenKey = 'token';
 
// 获取token
function getLocalToken () {
	return Cookies.get(TokenKey);
}
 
// 设置token
function setLocalToken (token) {
	return Cookies.set(TokenKey, token);
}
 
// 移除token
function removeLocalToken () {
	return Cookies.remove(TokenKey)
}

export {getLocalToken, setLocalToken, removeLocalToken}