<template>
  <div class="Devices">
    <div class="content">
        <p>在线h5端：</p>
        <div class="h5">{{ deviceList.h5List.length ? deviceList.h5List : "无在线设备" }}</div>
        <p>在线电脑端：</p>
        <div class="phone">{{ deviceList.phoneList.length ? deviceList.phoneList : "无在线设备"}}</div>
    </div>
  </div>
</template>

<script>
import { Button, Toast, Field, CellGroup, Dialog } from "vant";
import {
  reactive,
  ref,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { request, requestHeader } from "../../utils/request";
import VueQr from 'vue-qr/src/packages/vue-qr.vue';

export default {
  components: {
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Dialog.Component.name]: Dialog.Component,
    VueQr,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let placeAlias = route.params.placeAlias;
    let deviceList = reactive({
      h5List: ["加载中"],
      phoneList: ["加载中"],
    });

    // 获取在线设备列表
    const getOnlineDevices = (token) => {
      request({
        url: "/h5/lottery/getOnlineDevices",
        params: { "placeAlias": placeAlias },
        headers: {
          token: token,
        },
      }).then((res) => {
        if (res.success) {
          Object.assign(deviceList, res.result)
        } else {
          Toast.fail(res.error);
        }
      });
    };
    
    // 挂载后
    onMounted(() => {
      getOnlineDevices()
      setInterval(() => {
        getOnlineDevices()
      }, 5000);
    });

    return {
      placeAlias,
      deviceList,
      getOnlineDevices,
    };
  },
};
</script>

<style lang="less">
.Devices {

  margin-top: 50PX;
  margin-left: 10PX;
  font-size: 20PX;



}
</style>
