import { server, serverBtn } from './server'
import { getLocalToken, removeLocalToken } from './token'
// 这里的server其实就是aixos 只不过包含了我们配置的相关信息
 
function request({ url = '', method = 'get', data = {}, params = {}, headers = {token: getLocalToken() ? getLocalToken() : ''}}) {
    return server({
        url,
        method,
        data,
        params,
        headers
    })
}

function requestHeader({ url = '', method = 'get', data = {}, params = {}, type}) {
    let headers = {
        'Content-Type': type || 'application/x-www-form-urlencoded',
        'token': getLocalToken() ? getLocalToken() : ''
    }
    return server({
        url,
        method,
        data,
        params,
        headers
    })
}

function requestBtn({ url = '', method = 'get', data = {}, params = {}, type}, State) {
    if (State) {
		return new Promise((resolve, reject) => {
			reject('已有正在执行的请求')
		})
	}
    State.value = true

    let headers = {
        'Content-Type': type || 'application/x-www-form-urlencoded',
        'token': getLocalToken() ? getLocalToken() : ''
    }
    return serverBtn({
        url,
        method,
        data,
        params,
        headers
    })
}

export { request, requestHeader, requestBtn }