import { request, requestBtn } from "./request";
import { deleteParams } from "./util";
import {
  getLocalToken,
  setLocalToken,
  removeLocalToken,
} from "./token";
import { Toast } from "vant";
Toast.setDefaultOptions({ duration: 4000 });
var state = {
  appId: "wx80b78b98c99db46c",
}

function getUrlParam(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.slice(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

const getTokens = (code, getUserInfo) => {
  return request({
    url: '/weChat/auth',
    params: {
      'code': code,
      'appId': state.appId,
      'getUserInfo': getUserInfo
    }
  })
};

// 授权登录
function authBase() {
  let code = getUrlParam("code") // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取token

  if (!code) {
    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      state.appId +
      "&redirect_uri=" +
      encodeURIComponent(location.href) +
      "&response_type=code&scope=snsapi_base&state=base";
  } else {
    getTokens(code, false).then(res => {
      if(res.success) {
        setLocalToken(res.result);
        window.location.replace(deleteParams(deleteParams(location.href, 'state'), 'code'))
      } else {
        Toast.fail("服务异常")
      }
    })
  }
}

// 授权获取微信头像昵称
function authUserInfo() {
  let code = getUrlParam("code") // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取token

  if (!code) {
    window.location.href =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      state.appId +
      "&redirect_uri=" +
      encodeURIComponent(location.href) +
      "&response_type=code&scope=snsapi_userinfo&state=userinfo";
  } else {
    getTokens(code, true).then(res => {
      if (res.success) {
        setLocalToken(res.result);
        window.location.replace(deleteParams(deleteParams(location.href, 'state'), 'code'))
      } else {
        Toast.fail("服务异常")
      }
    })
  }
}

export { authBase, authUserInfo, getUrlParam }