// 日期处理
function formatter(type, val) {
  if (type === "year") {
    return val + "年";
  }
  if (type === "month") {
    return val + "月";
  }
  if (type === "day") {
    return val + "日";
  }
  return val;
};

// 获取当前年、月、日
function current() {
  let year, month, day;
  let date = new Date();
  year = date.getFullYear();
  month = date.getMonth();
  day = date.getDate();

  return [year, month, day];
}

// 比较时间
function compareDate(dateTime1, dateTime2) {
  var formatDate1 = new Date(dateTime1);
  var formatDate2 = new Date(dateTime2);
  if (formatDate1 > formatDate2) {
    return dateTime1;
  }
  else {
    return dateTime2;
  }
}

// 补零
function formatNumber (n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

//日期格式化
function formatTime(date, format = "yyyy-MM-dd hh:mm:ss") {
  var strDate = new Date(date)
  var year = strDate.getFullYear()
  var month = strDate.getMonth() + 1
  var day = strDate.getDate()
  var hour = strDate.getHours()
  var minute = strDate.getMinutes()
  var second = strDate.getSeconds()

  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

export { formatter, current, compareDate, formatTime }