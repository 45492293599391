import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
// 定义路由
import home from '../view/home'

import manual from '../view/manual'
import details from '../view/details'
import user from '../view/user'
import record from '../view/record'
import detailed from '../view/detailed'
import cash from '../view/cash'
import code from '../view/code'
import devices from '../view/devices'
import face from '../view/face'
import info from '../view/info'
import error from '../view/error'

import { getLocalToken } from "../utils/token";
import { Toast } from "vant";
import { authBase } from "../utils/auth";

// const routerHistory = createWebHashHistory()
const routerHistory = createWebHistory()
// 创建路由实例
const router = createRouter({
  // 采用hash 模式
  history: routerHistory,
  // 采用history 模式
  // history: routerHistory,
  // 定义路由实例
  routes: [
    {
      name: 'home',
      path: '/home/:placeAlias',
      component: home,
      meta: {
        title: '首页',
      }
    },
    {
      name: 'manual',
      path: '/manual/:placeAlias',
      component: manual,
      meta: {
        title: '手工兑奖',
      },
    },
    {
      name: 'details',
      path: '/details/:placeAlias',
      component: details,
      meta: {
        title: '兑奖详情',
      },
    },
    {
      name: 'user',
      path: '/user/:placeAlias',
      component: user,
      meta: {
        title: '钱包',
      }
    },
    {
      name: 'record',
      path: '/record/:placeAlias',
      component: record,
      meta: {
        title: '兑奖记录',
      },
    },
    {
      name: 'cash',
      path: '/cash/:placeAlias',
      component: cash,
      meta: {
        title: '提现'
      }
    },
    {
      name: 'detailed',
      path: '/detailed/:placeAlias',
      component: detailed,
      meta: {
        title: '钱包明细'
      }
    },
    {
      name: 'code',
      path: '/code/:placeAlias',
      component: code,
      meta: {
        title: '兑奖二维码'
      }
    },
    {
      name: 'devices',
      path: '/devices/:placeAlias',
      component: devices,
      meta: {
        title: '在线设备'
      }
    },
    {
      name: 'info',
      path: '/info/:placeAlias',
      component: info,
      meta: {
        title: '实名认证',
        keepAlive: true
      }
    },
    {
      name: 'face',
      path: '/face/:placeAlias',
      component: face,
      meta: {
        title: '未成年人识别'
      }
    },
    {
      name: 'error',
      path: '/error',
      component: error,
      meta: {
        title: 'error'
      }
    }
  ]
})

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
  // let list = ["manual", "details", "user", "record", "cash", "detailed"]
  // 判断有没有登录
  switch (to.name) {
    case "home":
    case "code":
    case "devices":
    case "error":
      next();
      break;
    case "manual":
    case "details":
    case "user":
    case "record":
    case "cash":
    case "detailed":
    case "info":
    case "face":
      if (!getLocalToken()) {
        Toast.fail('登录态过期, 重新登陆中...');
        authBase()
        // next("/error");
      } else {
        next();
      }
      break;
    default:
      next("/error");
  }
});
export default router