<template>
  <div class="Record">
    <div class="list">
      <div class="listBox" v-if="detailedList.length > 0">
        <van-list
          class="list_item"
          v-model:loading="recordDate.loading"
          :finished="recordDate.more"
          finished-text="没有更多了"
          @load="scroll"
        >
          <div
            class="item_box"
            v-for="(item, index) in detailedList"
            :key="index"
          >
            <div class="top">
              <div class="text">{{ item.deviceNo == "h5" ? "公众号兑奖" : "设备兑奖" }}</div>

              <div class="bonus">￥{{ item.money ? item.money : 0}}</div>
            </div>

            <div class="bottom">
              <div class="time">{{ formatTime(item.createTime) }}</div>

              <div
                class="info"
                :style="{ color: '#fd2d31' }"
              >
                {{item.cashRes ? item.cashRes : "兑奖失败"}}
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <div class="listBox noData" v-else>
        <van-empty description="暂无数据" image-size="50vw" />
      </div>
    </div>

    <!-- <div class="goBack">
      <div class="buttonBox">
        <van-button
          type="primary"
          :to="'/' + placeAlias" replace
          size="large"
          color="#FD0100"
          >返 回</van-button
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import { Button, Empty, Toast, List } from "vant";
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import { request, requestBtn } from "../../utils/request";
import { formatTime } from "../../utils/times";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
    [List.name]: List,
  },
  setup() {
    // const { ctx, proxy } = getCurrentInstance();
    // let placeAlias = proxy.$placeAlias.get();

    const route = useRoute();
    let placeAlias = route.params.placeAlias;

    // 中奖记录页数据
    let recordDate = reactive({
      pageNo: 1, //页数
      pageSize: 10, //列表个数
      loading: false, //加载图标
      more: false, //是否全部加载
    });
    // 中奖列表数据
    let detailedList = reactive([]);
    // 中奖记录请求
    const CoreLog = () => {
      let { pageNo, pageSize } = recordDate;
      let params = {
        pageNo,
        pageSize,
        placeAlias: placeAlias,
      };
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 1000,
      });
      return request({
        url: "/h5/lottery/pagingLotteryCashLog",
        params: params,
      }).then((res) => {
        let result = res.result;
        if (res.success) {
          if (result.total > detailedList.concat(result.data).length) {
            recordDate.more = false;
          } else {
            recordDate.more = true;
          }
          isSource(result.data);
          recordDate.loading = false;
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 列表数据处理
    const isSource = (list) => {
      for (let i = 0; i < list.length; i++) {
        let type = list[i].source;
        let sourceName;
        switch (type) {
          case 1:
          sourceName = "扫码中奖";
            break;
          case 4:
          sourceName = "设备领奖";
            break;
        }
        list[i].sourceName = sourceName;
      }
      Object.assign(detailedList, detailedList.concat(list));
    };
    // 滚动条到底加载更多
    const scroll = () => {
      recordDate.loading = true;
      recordDate.pageNo += 1;
      CoreLog();
    };

    onMounted(() => {
      CoreLog();
    });

    return {
      recordDate,
      detailedList,
      placeAlias,
      scroll,
      formatTime,
    };
  },
};
</script>

<style lang="less" scoped>
.Record {
  background: #f0eff4;
  height: 100vh;

  .list {
    width: 100vw;

    .listBox {
      padding: 0 20px;

      .list_item {
        height: calc(100vh - 70px);
        overflow-y: scroll;

        .item_box {
          padding: 20px 0 10px 0;
          border-bottom: 1px solid #dcdbe0;

          .top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .text {
              font-size: 14px;
              color: #3d3c41;
              font-weight: bold;
            }

            .bonus {
              font-size: 16px;
              font-weight: bold;
              color: #fd2d31;
            }
          }

          .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
              font-size: 12px;
              color: #9d9ca1;
            }

            .info {
              font-size: 12px;
              font-weight: 550;
            }
          }
        }
      }
    }

    .noData {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .goBack {
    position: fixed;
    bottom: 0;
    background: #f0eff4;
    z-index: 99;
    width: 100%;

    .buttonBox {
      padding: 10px;

      .van-button {
        border: none;
        height: 46px;
        font-size: 16px;
        border-radius: 6px !important;
      }
    }
  }
}
</style>
