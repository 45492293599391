<template>
  <div class="Home">
    <img class="bg_img" src="../../assets/back_img.jpeg" alt="" />

    <div class="content">
      <div class="header">
        <!-- 用户头像和用户名称 -->
        <div class="user_info" v-if="!userInfo.headImageUrl" @click="getUserBaseInfo">
          <van-icon name="manager" />
          登录
        </div>
        <div class="user_info" v-else>
          <van-icon :name="userInfo.headImageUrl" />
          {{ userInfo.name }}
        </div>
      </div>

      <!-- 广告 -->
      <div class="poster">
        <img src="../../assets/banner_img2.png" alt="" />
      </div>

      <!-- 操作区 -->
      <div class="operation">
        <!-- 扫码 -->
        <div class="scanCord">
          <!-- 扫码按钮 -->
          <div class="but_box">
            <van-button
              class="scanBut"
              color="linear-gradient( #EE6226, #EA322D)"
              @click="scanCode"
            >
              <van-icon name="scan" />
              扫码兑奖
            </van-button>
          </div>

          <div class="skip_box">
            <router-link class="button" :to="{name:'record', params: {placeAlias: placeAlias}}">兑奖记录</router-link>

            <router-link class="button" :to="{name:'user', params: {placeAlias: placeAlias}}">
              奖金提现
              <div class="tips" v-if="userInfo.lotteryCore">1</div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- 兑奖说明和手工兑奖 -->
      <!-- <div class="help_area">
        <div class="help_box">
          <div class="explain" @click="explainF">兑奖说明</div>

          <div class="manual">
            <div class="explain" @click="toManual">手工兑奖</div>
          </div>
        </div>

        <Popup
          :popupState="popupState"
          @confirmRevise="confirmRevise"
          @cancelRevise="cancelRevise"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  reactive,
  ref,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
} from "vue";
import { Icon, Button, Toast, Dialog } from "vant";
Toast.setDefaultOptions({ duration: 4000 });
import Popup from "../../components/Popup.vue";
import { request, requestHeader } from "../../utils/request";
import {
  getLocalToken,
  setLocalToken,
  removeLocalToken,
} from "../../utils/token";
import { authBase, authUserInfo, getUrlParam } from "../../utils/auth";
import qs from "qs";
import { deleteParams } from "../../utils/util";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    Popup,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const { ctx, proxy } = getCurrentInstance();
    let { pathname, search } = location;
    search = search.slice(1)
    let placeAlias = route.params.placeAlias;
    let dcode = qs.parse(search).dcode; // 设备码带登录时间戳
    let dinfo = qs.parse(search).dinfo; // 设备码带登录时间戳
    let orderNo = qs.parse(search).orderNo;
    // let isRealname = false;
    let isAdult = false;
    //开发
    // proxy.$placeAlias.set(placeAlias);
    // if (location.host == "localhost:8091") {
    //   setLocalToken("a7a367e6fab34e04998a08087e7835f2");
    // }

    let popupState = reactive({
      DialogShow: false,
      title: "兑奖说明",
      confirm_button_text: "同意",
      cancel_button_text: "不同意",
    });
    let indexData = reactive({
      LoginStatus: false, //用户登录状态
    });
    // 用户信息
    let userInfo = reactive({
      name: "登录",
      headImageUrl: "manager",
    });
    // 获取头像昵称
    const getUserBaseInfo = () => {
      authUserInfo()
    };
    // 提现跳转
    const toUser = () => {
      if (!getLocalToken()) {
        Toast.fail("请先点击左上角登录");
        return;
      }
      router.push({ name: 'user', params: { placeAlias: placeAlias } });
    };
    // 兑奖说明点击事件
    const explainF = () => {
      popupState.DialogShow = true;
    };
    // 手工兑奖跳转
    const toManual = () => {
      router.push({name:'manual', params: {placeAlias:placeAlias}});
    };

    const confirmRevise = (val) => {
      console.log(popupState);
      // popupState.DialogShow=val.data;
    };

    const cancelRevise = (val) => {
      console.log(popupState);
      // popupState.DialogShow = val.data;
    };
    //需要
    const onNeed = () => {
      // 判断是否有token
      let token = getLocalToken();
      let state = getUrlParam('state')
      if (token) {
        loadMore(token);
      } else {
        if (state == 'userinfo') {
          authUserInfo()
        } else {
          Dialog.alert({
            message: '授权登录后可继续操作'
          }).then((res) => {
            authUserInfo();
          })
        }
      }
    };
    // 挂载后
    onMounted(() => {
      onNeed()
    });
    // 登录设备
    const deviceLogin = () => {
      if (!dcode) return;
      let deviceNo = dcode.slice(0, 6)
      let timeStamp = dcode.slice(6)
      let formData = new FormData();
      formData.append("deviceNo", deviceNo);
      formData.append("timeStamp", timeStamp);
      request({
        url: "/h5/lottery/deviceLogin",
        method: "post",
        data: formData,
      }).then((res) => {
        let message;
        if (res.success) {
          message = '登录成功, 请在设备上扫码兑奖'
        } else {
          message = res.error
        }
        Dialog.alert({
          message: message,
        }).then(() => {
          // 退出网页
          setTimeout(function() {
            //这个可以关闭安卓系统的手机
            document.addEventListener(
              "WeixinJSBridgeReady",
              function() {
                WeixinJSBridge.call("closeWindow");
              },
              false
            );
            //这个可以关闭ios系统的手机
            WeixinJSBridge.call("closeWindow");
          }, 300);
        });
      });
    };
    // 实名认证
    // const realnameAuth = () => {
    //   if (!dinfo) return;
    //   let deviceNo = dinfo.slice(0, 6)
    //   let timeStamp = dinfo.slice(6)
    //   if (isRealname) { // 已实名, 通知设备进行下一步
    //     let formData = new FormData();
    //     formData.append("deviceNo", deviceNo);
    //     formData.append("timeStamp", timeStamp);
    //     request({
    //       url: "/h5/lottery/realnameOk",
    //       method: "post",
    //       data: formData,
    //     }).then((res) => {
    //       let message;
    //       if (res.success) {
    //         message = '实名验证通过，请在机器上继续操作购彩'
    //       } else {
    //         message = res.error
    //       }
    //       Dialog.alert({
    //         message: message,
    //       }).then(() => {
    //         // 退出网页
    //         setTimeout(function() {
    //           //这个可以关闭安卓系统的手机
    //           document.addEventListener(
    //             "WeixinJSBridgeReady",
    //             function() {
    //               WeixinJSBridge.call("closeWindow");
    //             },
    //             false
    //           );
    //           //这个可以关闭ios系统的手机
    //           WeixinJSBridge.call("closeWindow");
    //         }, 300);
    //       });
    //     });
    //   } else { // 未实名
    //     router.push({ name: 'info', params: { placeAlias: placeAlias } });
    //   }
    // };
    // 实名认证
    const adultAuth = () => {
      if (!dinfo) return;
      let deviceNo = dinfo.slice(0, 6)
      let timeStamp = dinfo.slice(6)
      if (isAdult) { // 已实名, 通知设备进行下一步
        let formData = new FormData();
        formData.append("deviceNo", deviceNo);
        formData.append("timeStamp", timeStamp);
        request({
          url: "/h5/lottery/adultOk",
          method: "post",
          data: formData,
        }).then((res) => {
          let message;
          if (res.success) {
            message = '您已成年，请在机器上继续操作购彩'
          } else {
            message = res.error
          }
          Dialog.alert({
            message: message,
          }).then(() => {
            // 退出网页
            setTimeout(function() {
              //这个可以关闭安卓系统的手机
              document.addEventListener(
                "WeixinJSBridgeReady",
                function() {
                  WeixinJSBridge.call("closeWindow");
                },
                false
              );
              //这个可以关闭ios系统的手机
              WeixinJSBridge.call("closeWindow");
            }, 300);
          });
        });
      } else { // 未实名
        router.replace({ name: 'face', params: { placeAlias: placeAlias, deviceNo: deviceNo, timeStamp: timeStamp } });
      }
    };
    const loadMore = (token) => {
      indexData.LoginStatus = true;
      getUserInfo(token);
    };
    // 获取用户信息
    const getUserInfo = (token) => {
      request({
        url: "/h5/lottery/getUserInfo",
        params: {"placeAlias": placeAlias},
        headers: {
          token: token,
        },
      }).then((res) => {
        if (res.success) {
          Object.assign(userInfo, res.result);
          if (res.result.isAdult) { // 已实名
            isAdult = true;
          }
          if (dcode) {
            deviceLogin();
            return
          }
          if (dinfo) {
            adultAuth();
            return
          }
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 扫码点击事件
    const scanCode = () => {
      // Toast.fail("扫码兑奖服务已关闭，请到设备上或附近体彩店兑奖");
      // return;
      if (!getLocalToken()) {
        Toast.fail("请先点击左上角登录");
        return;
      }
      // 微信扫一扫功能
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode", "datamatrix"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          console.log(res);
          let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          if (result.includes("DATA_MATRIX")) {
            let scan_num = result.slice(12);
            console.log(scan_num);
            getLotteryRes(scan_num);
          } else {
            Toast("请扫码正确的彩票兑奖码");
          }
        },
      });
    };
    // 查询彩票中奖信息
    const getLotteryRes = (code) => {
      Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let formData = new FormData();
      formData.append("code", code);
      formData.append("placeAlias", placeAlias);
      request({
        url: "/h5/lottery/getLotteryRes",
        method: "post",
        data: formData,
      }).then((res) => {
        Toast.clear();
        if (res.success) {
          Dialog.alert({
            message: res.result,
          }).then(() => {});
        } else {
          Dialog.alert({
            message: res.error,
          }).then(() => {});
          // Toast.fail(res.error);
        }
      });
    };

    return {
      popupState,
      userInfo,
      indexData,
      placeAlias,
      explainF,
      toUser,
      confirmRevise,
      cancelRevise,
      scanCode,
      getUserBaseInfo,
      toManual,
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.Home {
  height: 100vh;

  .bg_img {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -10;
  }

  .content {
    padding: 0 20px 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user_info {
        color: white;
        font-size: 16px;
        padding: 20px 0;
        display: flex;
        align-items: center;

        .van-icon {
          font-size: 40px;
          margin-right: 10px;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      .complete {
        font-size: 16px;
        font-weight: bold;
        color: #0495da;
      }
    }

    .poster {
      overflow: hidden;
      border-radius: 10px;
      height: 34vh;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
      }
    }

    .operation {
      background: white;
      padding: 30px 20px 0;
      margin-top: 20px;
      border-radius: 10px;

      .but_box {
        .scanBut {
          width: 100%;
          height: 100px;
          border-radius: 5px;
          font-size: 20px;
        }
      }

      .skip_box {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 60px;

        .button {
          position: relative;

          .tips {
            position: absolute;
            color: white;
            width: 14px;
            height: 14px;
            font-size: 13px;
            text-align: center;
            border-radius: 50%;
            top: -6px;
            right: -12px;
            background-color: #f00;
          }
        }

        a, div {
          font-size: 14px;
          color: black;
          font-weight: bold;
        }
      }
    }

    .help_area {
      .help_box {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 60px;

        .explain {
          font-size: 14px;
          color: white;
        }

        .manual {
          display: flex;
          align-items: center;

          a {
            font-size: 14px;
            color: white;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
