<template>
  <div class="Detailed">
    <div class="header">
      <van-button plain type="primary" @click="allClick">{{
        detailedData.allTxt
      }}</van-button>

      <div class="tiemSel">
        <van-button plain type="primary" @click="startClick">{{
          detailedData.startTime
        }}</van-button>
        至
        <van-button plain type="primary" @click="endClick">{{
          detailedData.endTime
        }}</van-button>
      </div>
    </div>

    <div class="listBox" v-if="filterList.length > 0">
      <van-list
        class="list_item"
        v-model:loading="detailedData.loading"
        :finished="detailedData.more"
        finished-text="没有更多了"
        @load="scroll"
      >
        <div class="term" v-for="(item, index) in filterList" :key="index">
          <div class="date">订单日期：{{ formatTime(item.createTime) }}</div>

          <div class="box">
            <div class="state">
              {{ item.name }}：
              <span v-if="item.core > 0" style="color: #45e64f"
                >+{{ item.core }}</span
              >
              <span v-else style="color: #ff0000">{{ item.core }}</span>
            </div>

            <div class="balance">
              钱包余额:
              <span>{{ item.lotteryCore }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <div class="listBox noData" v-else>
      <van-empty description="暂无数据" image-size="50vw" />
    </div>

    <van-popup
      v-model:show="detailedData.allShow"
      position="bottom"
      :style="{ height: '45%', overflow: 'hidden' }"
    >
      <van-picker
        :columns="selItem"
        @confirm="allConfirm"
        @cancel="allCancel"
      />
    </van-popup>

    <van-popup
      v-model:show="detailedData.startShow"
      position="bottom"
      :style="{ height: '45%', overflow: 'hidden' }"
    >
      <van-datetime-picker
        v-model="dates.startDate"
        type="date"
        :formatter="formatter"
        @confirm="confirmS"
        @cancel="cancelS"
      />
    </van-popup>

    <van-popup
      v-model:show="detailedData.endShow"
      position="bottom"
      :style="{ height: '45%', overflow: 'hidden' }"
    >
      <van-datetime-picker
        v-model="dates.endDate"
        type="date"
        :formatter="formatter"
        @confirm="confirmE"
        @cancel="cancelE"
      />
    </van-popup>
  </div>
</template>

<script>
import { reactive, ref, onBeforeMount, onMounted } from "vue";
import {
  Button,
  Popup,
  Picker,
  Toast,
  DatetimePicker,
  Empty,
  List,
} from "vant";
import { formatter, current, compareDate, formatTime } from "../../utils/times";
import { request, requestBtn } from "../../utils/request";
import { listDetailed, onMosewheel } from "../../utils/page";
import placeAlias from '@/utils/placeAlias';
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Empty.name]: Empty,
    [List.name]: List,
    [Toast.name]: Toast,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    const route = useRoute();
    let placeAlias = route.params.placeAlias;
    // 钱包明细页数据
    let detailedData = reactive({
      pageNo: 1, //页数
      pageSize: 10, //列表个数
      loading: false, //加载图标
      more: false, //是否全部加载
      allTxt: "全部",
      startTime: "开始时间",
      endTime: "结束时间",
      allShow: false, // 全部的显示变量
      startShow: false, // 开始的显示变量
      endShow: false, // 结束的显示变量
    });
    // 钱包明细列表数据
    let detailedList = reactive([]);
    // 筛选数据
    let filterList = reactive([]);

    // 全部的点击事件
    const allClick = () => {
      detailedData.allShow = true;
    };
    // 开始的点击事件
    const startClick = () => {
      detailedData.startShow = true;
    };
    // 结束的点击事件
    const endClick = () => {
      detailedData.endShow = true;
    };
    // 全部的选择项
    const selItem = ["全部", "扫码中奖", "提现", "提现失败退款", "设备领奖"];
    // 全部确认回调
    const allConfirm = (value, index) => {
      detailedData.allTxt = value;
      detailedData.allShow = false;
      filterList.length = 0;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });

      Object.assign(filterList, dataFilter(value));
    };
    // 全部取消回调
    const allCancel = () => {
      detailedData.allShow = false;
    };
    // 时间选择器时间
    let currentDate = current();
    const dates = reactive({
      startDate: new Date(...currentDate),
      endDate: new Date(...currentDate),
    });

    // 开始确认回调
    const confirmS = (value) => {
      let result = compareDate(value, detailedData.endTime);
      if (result === detailedData.endTime) {
        let times = value.toJSON();
        detailedData.startTime = times.slice(0, times.indexOf("T"));
        Reset();
        CoreLog();
      } else {
        detailedData.startTime = "开始时间";
        Toast.fail("开始时间必须小于开始时间");
      }
      detailedData.startShow = false;
    };
    // 开始取消回调
    const cancelS = (value) => {
      detailedData.startShow = false;
    };
    // 结束确认回调
    const confirmE = (value) => {
      if (detailedData.startTime != "开始时间") {
        let result = compareDate(detailedData.startTime, value);
        if (result === value) {
          let times = value.toJSON();
          detailedData.endTime = times.slice(0, times.indexOf("T"));
          Reset();
          CoreLog();
        } else {
          detailedData.endTime = "结束时间";
          Toast.fail("结束时间必须大于开始时间");
        }
      } else {
        let times = value.toJSON();
        detailedData.endTime = times.slice(0, times.indexOf("T"));
      }

      detailedData.endShow = false;
    };
    // 结束取消回调
    const cancelE = (value) => {
      detailedData.endShow = false;
    };

    // 数据筛选
    let dataFilter = (condition) => {
      let newList = [];
      newList = detailedList.filter((item) => {
        if (condition === "全部") {
          return item;
        } else if (condition === "扫码中奖") {
          return item.source === 1;
        } else if (condition === "提现") {
          return item.source === 2;
        } else if (condition === "提现失败退款") {
          return item.source === 3;
        } else if (condition === "设备领奖") {
          return item.source === 4;
        }
      });
      Toast.clear();
      return newList;
    };

    // 钱包明细请求
    const CoreLog = () => {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });
      let { pageNo, pageSize, startTime, endTime } = detailedData;
      let newData = {
        pageNo: pageNo,
        pageSize: pageSize,
        placeAlias: placeAlias,
        sourceList: '1,2,3,4',
      };

      if (startTime != "开始时间") {
        newData.startTime = startTime;
      }
      if (detailedData.endTime != "结束时间") {
        newData.endTime = endTime;
      }

      request({
        url: "/h5/lottery/pagingLotteryCoreLog",
        params: newData,
      }).then((res) => {
        Toast.clear();
        let result = res.result;
        if (res.success) {
          if (result.total > detailedList.concat(result.data).length) {
            detailedData.more = false;
          } else {
            detailedData.more = true;
          }
          isSource(result.data);
          detailedData.loading = false;
        } else {
          Toast.fail(res.error);
        }
      });
    };
    // 列表数据处理
    const isSource = (list) => {
      for (let i = 0; i < list.length; i++) {
        let type = list[i].source;
        let name;
        switch (type) {
          case 1:
            name = "扫码中奖";
            break;
          case 2:
            name = "提现金额";
            break;
          case 3:
            name = "提现失败退款";
            break;
          case 4:
            name = "设备领奖";
            break;
        }
        list[i].name = name;
      }
      Object.assign(detailedList, detailedList.concat(list));
      Object.assign(filterList, dataFilter(detailedData.allTxt));
    };
    // 滚动条到底加载更多
    const scroll = () => {
      detailedData.loading = true;
      detailedData.pageNo += 1;
      CoreLog();
    };
    // 重置
    const Reset = () => {
      filterList.length = 0;
      detailedList.length = 0;
      detailedData.pageNo = 1;
      detailedData.pageSize = 10;
      detailedData.more = false;
    };

    onMounted(() => {
      CoreLog();
    });

    return {
      detailedList,
      filterList,
      allClick,
      startClick,
      endClick,
      allConfirm,
      allCancel,
      selItem,
      dates,
      confirmS,
      cancelS,
      confirmE,
      cancelE,
      detailedData,
      CoreLog,
      scroll,
      formatter,
      formatTime,
    };
  },
};
</script>
<style lang="less" scoped>
.Detailed {
  height: 100vh;
  background: white;

  .header {
    background: #2d3035;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 10px;

    .van-button {
      width: 100px;
      height: 30px;
      font-size: 14px;
      color: white;
      background: #2d3035;
      border: 1px solid #929292;
    }

    .tiemSel {
      padding-left: 15px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: white;

      .van-button {
        padding: 0;
        height: 30px;
        font-size: 14px;
        color: white;
        background: #2d3035;
        border: 1px solid #929292;
      }
    }
  }

  .listBox {
    border-top: 5px solid #f0eff4;
    .list_item {
      height: calc(100vh - 55px);
      overflow-y: scroll;

      .term {
        border-bottom: 2px solid #dddddd;
        padding: 15px;
        font-size: 16px;
        color: #6d6d6e;

        .order {
          display: flex;
          align-items: center;
          & > span {
            width: 70px;
            text-align-last: justify;
          }
        }

        .box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .balance {
            display: flex;
            align-items: center;
            & > span {
              color: #00ccff;
            }
          }
        }
      }
    }
  }

  .content {
    height: calc(100vh - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    background: #dddddd;

    .term {
      background: white;
      border-top: 2px solid #dddddd;
      padding: 15px;
      font-size: 14px;
      color: #6d6d6e;

      .order {
        display: flex;
        align-items: center;
        & > span {
          width: 90px;
          text-align-last: justify;
        }
      }

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .balance {
          display: flex;
          align-items: center;
          & > span {
            color: #00ccff;
          }
        }
      }
    }
  }

  .van-loading {
    border-radius: 10px;
    width: 20vw;
    height: 20vw;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(88, 88, 88, 0.8);
  }
}
</style>
