<template>
  <div class="Manual">
    <div class="srtp_img">
      <img src="../../assets/manual_img.png" alt="" />
    </div>

    <div class="context">
      <div class="title">
        <div class="txt">
          <span> ① </span>
          兑奖区旁票号
        </div>
        <div class="txt">
          <span> ② </span>
          刮开兑奖区输入兑奖码
        </div>
      </div>

      <div class="ticket">
        <div class="txtExplain">
          1、请输入兑奖旁边的16或者18位
          <span>票号</span>
        </div>

        <div class="inputArea">
          <!-- <input
            type="number"
            v-model="ticket.ticket1"
            style="width: 12%"
          /> -->

          <van-cell-group inset style="width: 12%">
            <van-field
              v-model="ticket.ticket1"
              type="digit"
              maxlength="2"
              input-align="center"
              id="manual_inp1"
            />
          </van-cell-group>

          <span></span>

          <van-cell-group inset style="width: 20%">
            <van-field
              v-model="ticket.ticket2"
              type="digit"
              maxlength="4"
              input-align="center"
              id="manual_inp2"
            />
          </van-cell-group>

          <span></span>

          <van-cell-group inset style="width: 40%">
            <van-field
              v-model="ticket.ticket3"
              type="digit"
              maxlength="9"
              input-align="center"
              id="manual_inp3"
            />
          </van-cell-group>

          <span></span>

          <van-cell-group inset style="width: 12%">
            <van-field
              v-model="ticket.ticket4"
              type="digit"
              maxlength="3"
              input-align="center"
              id="manual_inp4"
            />
          </van-cell-group>
        </div>
      </div>

      <div class="ticket">
        <div class="txtExplain">
          2、请刮开<span>兑奖区</span>，输入11位<span>兑奖码</span>
        </div>

        <div class="inputArea">
          <van-cell-group inset style="width: 34%">
            <van-field
              v-model="prizeCode.prizeCode1"
              type="digit"
              maxlength="4"
              input-align="center"
              id="manual_inp5"
            />
          </van-cell-group>

          <span></span>

          <van-cell-group inset style="width: 34%">
            <van-field
              v-model="prizeCode.prizeCode2"
              type="digit"
              maxlength="4"
              input-align="center"
              id="manual_inp6"
            />
          </van-cell-group>

          <span></span>

          <van-cell-group inset style="width: 20%">
            <van-field
              v-model="prizeCode.prizeCode3"
              type="digit"
              maxlength="3"
              input-align="center"
              id="manual_inp7"
            />
          </van-cell-group>
        </div>
      </div>
    </div>

    <div class="submit">
      <div class="submit_box">
        <div class="scan">
          <router-link :to="'/' + placeAlias" replace>扫码兑奖</router-link>
        </div>

        <van-button type="primary" size="large" @click="submit"
          >确认提交</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onBeforeMount, onMounted, getCurrentInstance } from "vue";
import { Button, Toast, Field, CellGroup, Dialog } from "vant";
import { request, requestHeader } from "../../utils/request";
import { useRoute } from "vue-router";

export default {
  components: {
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    // 用户所在地
    const route = useRoute();
    let placeAlias = route.params.placeAlias;

    let ticket = reactive({
      ticket1: "",
      ticket2: "",
      ticket3: "",
      ticket4: "",
    });
    let prizeCode = reactive({
      prizeCode1: "",
      prizeCode2: "",
      prizeCode3: "",
    });
    // 提交
    const submit = () => {
      let ticketsCode = "";
      let prizesCode = "";
      for (const key in ticket) {
        if (Object.hasOwnProperty.call(ticket, key)) {
          const element = ticket[key];
          ticketsCode = ticketsCode.concat(element);
        }
      }
      for (const key in prizeCode) {
        if (Object.hasOwnProperty.call(prizeCode, key)) {
          const element = prizeCode[key];
          prizesCode = prizesCode.concat(element);
        }
      }
      if (ticketsCode.length < 16) {
        Toast("请输入完整的票号");
      } else if (prizesCode.length < 11) {
        Toast("请输入完整的兑奖码");
      } else {
        getLotteryRes(ticketsCode + prizesCode);
      }
      // Toast.success('提交成功');
    };
    // 查询彩票中奖信息
    const getLotteryRes = (code) => {
      Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let formData = new FormData();
      formData.append("code", code);
      formData.append("isHand", true);
      formData.append("placeAlias", placeAlias);
      request({
        url: "/h5/lottery/getLotteryRes",
        method: "post",
        data: formData,
        type: "form-data",
      }).then((res) => {
        Toast.clear();
        if (res.success) {
          Dialog.alert({
            message: res.result,
          }).then(() => {clear();});
        } else {
          Dialog.alert({
            message: res.error,
          }).then(() => {clear();});
          // Toast.fail(res.error);
        }
      });
    };
    // 输入框清空
    const clear = () => {
      for (const key in ticket) {
        if (Object.hasOwnProperty.call(ticket, key)) {
          ticket[key] = "";
        }
      }
      for (const key in prizeCode) {
        if (Object.hasOwnProperty.call(prizeCode, key)) {
          prizeCode[key] = "";
        }
      }
    };

    return {
      ticket,
      prizeCode,
      placeAlias,
      submit,
    };
  },
};
</script>

<style lang="less">
.Manual {
  .srtp_img {
    padding: 10px;

    img {
      width: 100%;
    }
  }

  .context {
    padding: 0 10px;

    .title {
      display: flex;
      align-items: center;

      .txt {
        display: flex;
        align-items: center;
        width: 50%;
        font-size: 14px;
        color: black;

        span {
          font-size: 16px;
          line-height: 100%;
        }
      }
    }

    .ticket {
      margin-top: 20px;

      .txtExplain {
        font-size: 14px;
        font-weight: bold;

        span {
          color: #f10744;
        }
      }

      .inputArea {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .van-cell-group {
          border-radius: 0;
          margin: 0;

          .van-cell {
            padding: 0;

            .van-cell__value {
              .van-field__body {
                input {
                  font-size: 14px;
                  border: 1px solid #cccccc;
                  height: 30px;
                }
              }
            }
          }
        }

        span {
          margin: 0 10px;
          width: 3%;
          border-bottom: 1px solid #757575;
        }
      }
    }
  }

  .submit {
    position: fixed;
    bottom: 20px;
    width: 100%;

    .submit_box {
      padding: 0 10px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .scan {
        margin-bottom: 10px;
        text-align: center;

        a {
          font-size: 16px;
          font-weight: bold;
          color: #f10744;
        }
      }

      .van-button {
        border: none;
        border-radius: 8px;
        height: 45px;
        background: #f10744;
        color: white;
        font-size: 14px;
      }
    }
  }
}
</style>
