<template>
  <div class="Popup">
    <van-dialog
      v-model:show="todos.DialogShow"
      :title="todos.title"
      :confirm-button-text="todos.confirm_button_text"
      :cancel-button-text="todos.cancel_button_text"
      show-cancel-button
      @confirm="confirm"
      @cancel="cancel"
      allow-html
    >
      <div class="text_box">
        <p class="text">
          请在使用“即开票微信兑奖（以下简称微信兑奖或本系统）
          系统需同意以下说明，请详细阅读：
          <br />
          <br />
          1、使用微信兑奖前，请先确认本微信号已实名认证，否则兑奖可能无法到账，因此造成的损失需您自己承担；
          <br />
          2、如您未满十八岁，请勿使用本系统； <br />
          3、单张彩票中奖1万元(包含1万元)以下可以使用本系统兑奖，中得1万元（不含1万元）以上金额，请持中奖彩票和本人有效身份证件到当地体彩中心兑奖；
          <br />
          4、每位用户单日提现限10次，每次提现金额不得超过500元，超过10次无法继续提现；可于次日继续操作。
          <br />
          5、本系统仅支持{{place}}省体育彩票发行中心承销的即开型体育彩票； <br />
          6、因即开票兑奖基于微信平台，所以兑奖速率有些许延迟，请广大彩民耐心等待。
          <br />
          使用本系统请同意以上说明，并点击“同意”；如您不同意以上说明，请点击“不同意”并停止使用本系统。
        </p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { reactive } from "@vue/reactivity";
import { useRouter, useRoute } from "vue-router";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: ["popupState"],
  setup(props, { emit }) {
    const route = useRoute();
    let placeAlias = route.params.placeAlias
    let place = ''
    switch (placeAlias) {
      case "lu":
        place = "山东"        
        break;
      case "shu":
        place = "重庆"
        break;
      default:
        break;
    }

    let todos = reactive(props.popupState);

    const confirm = () => {
      // emit("confirmRevise",{data:false})
    };

    const cancel = () => {
      // emit("cancelRevise",{data:false})
    };

    return {
      todos,
      place,
      confirm,
      cancel,
    };
  },
};
</script>

<style lang="less">
.Popup {
  .van-dialog {
    top: 50% !important;
    border-radius: 8px !important;

    .van-dialog__header {
      font-weight: bold;
      padding: 15px 0 !important;
    }

    .van-dialog__content {
      padding: 0 30px;

      .text_box {
        height: 48vh;

        .text {
          height: 100%;
          width: 100%;
          padding-right: 60px;
          overflow-y: scroll;
          margin: 0;
          font-size: 14px;
          color: #969696;
        }
      }
    }

    .van-dialog__footer {
      padding: 20px 30px;
      justify-content: space-between;
      align-items: center;

      .van-dialog__cancel {
        width: 45%;
        flex: none !important;
        background: #D2AEB0;
        height: 35px;
        .van-button__content {
          color: white;
          font-size: 14px;
        }
      }

      .van-dialog__confirm {
        width: 45%;
        flex: none !important;
        background: #EC2739;
        height: 35px;
        .van-button__content {
          color: white;
          font-size: 14px;
        }
      }
    }
  }
}
</style>